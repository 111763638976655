import { isAllowNoTravelDocsTrainings } from "../services/settings";

export function isResumeComplete(user) {
  if (!user) return false;
  const {
    wexp,
    exp,
    edu,
    bio,
    dob,
    height,
    weight,
    civilstatus,
    fullname,
    bigpic,
    email,
    address,
    phonenumber,
    country,
    train,
  } = user;

  let hasTravelDocsTrainings = true;

  if (!isAllowNoTravelDocsTrainings()) {
    hasTravelDocsTrainings = train && train.length > 0;
    // hasTravelDocsTrainings =
    //   train && train.length > 0 && travel && travel.length > 0;
  }
  // console.log(user);
  if (
    ((wexp && wexp.length > 0) || (exp && exp.length > 0)) &&
    edu &&
    edu.length > 0 &&
    bio &&
    dob &&
    height &&
    weight &&
    civilstatus &&
    fullname &&
    bigpic &&
    email &&
    address &&
    phonenumber &&
    country &&
    hasTravelDocsTrainings
  ) {
    return true;
  }
  return false;
}

export function isAllowApplyNoExperience(user) {
  if (!user) return false;
  const {
    edu,
    bio,
    dob,
    height,
    weight,
    civilstatus,
    fullname,
    bigpic,
    email,
    address,
    phonenumber,
    country,
    train,
  } = user;

  let hasTravelDocsTrainings = true;

  if (!isAllowNoTravelDocsTrainings()) {
    hasTravelDocsTrainings = train && train.length > 0;
    // hasTravelDocsTrainings =
    //   train && train.length > 0 && travel && travel.length > 0;
  }
  // console.log(user);
  if (
    edu &&
    edu.length > 0 &&
    bio &&
    dob &&
    height &&
    weight &&
    civilstatus &&
    fullname &&
    bigpic &&
    email &&
    address &&
    phonenumber &&
    country &&
    hasTravelDocsTrainings
  ) {
    return true;
  }
  return false;
}

export function isRequireCVUpload(user) {
  if (!user) return false;
  const { wexp, exp, edu, train } = user;
  // console.log(user);
  let hasTravelDocsTrainings = true;

  if (!isAllowNoTravelDocsTrainings()) {
    hasTravelDocsTrainings = train && train.length > 0;
    // hasTravelDocsTrainings =
    //   train && train.length > 0 && travel && travel.length > 0;
  }
  // console.log(user);
  if (
    ((wexp && wexp.length > 0) || (exp && exp.length > 0)) &&
    edu &&
    edu.length > 0 &&
    hasTravelDocsTrainings
  ) {
    return false;
  }
  return true;
}
