import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import fire from "./services/fire";
import LoadingSpinner from "./core/common/loadingSpinner";
import AsyncLoginForm from "./core/account/async/asyncLogin";
import AsyncForgotPassword from "./core/account/async/asyncForgot";
import AsyncRegisterSuccess from "./core/account/async/asyncRegsiterSuccess";
import AsyncAuthHandler from "./core/account/async/asyncAuthHandler";
import AsyncProfile from "./core/account/async/asyncProfile";
import AsyncMainPage from "./core/account/async/asynMainPage";
import AsyncAccountEdit from "./core/account/async/asyncAccountEdit";
import ProtectedRoute from "./core/route/protectedRoute";
import WelcomePage from "./core/welcome/page/welcomePage";
import AsyncRolesSetup from "./core/roles/async/asyncRolesSetup";
import AsyncUserList from "./core/admin/async/asyncUserList";
import AsyncAdminDashboard from "./core/admin/async/asyncAdminDashboard";
import AsyncPageNotFound from "./core/staticpages/async/asyncPageNotFound";
import AsyncInvalidUserList from "./core/admin/async/asyncInvalidUserList";
// import RegisterForm from "./core/account/page/register";
import AsyncAgencyList from "./core/agencies/async/asyncProfile";
import AsyncPost from "./core/quickpost/async/asyncPost";
import AsyncPostJobs from "./core/quickpost/async/asyncPostJobs";
import AsyncViewAllPosts from "./core/quickpost/async/asyncViewAllPosts";
import AsyncTravel from "./core/travel/async/asyncTravel";
import AsyncTestimonials from "./core/testimonials/async/asyncTestimonials";
import AsyncFBGroups from "./core/admin/async/asyncFBGroups";
import AsyncUserStats from "./core/stats/async/asyncUserStats";
import AsyncPrivacy from "./core/privacy/async/asyncPrivacy";
import AsyncViewAgencyAllJobPosts from "./core/quickpost/async/asyncViewAgencyAllJobPosts";
import AsyncViewApplications from "./core/quickpost/async/asyncViewApplications";
import AsyncDashboard from "./core/dashboard/async/asyncDashboard";
import {
  getWebsitePrefix,
  getWebsiteSuffix,
  getWebVersion,
  isGlobalPublicPageEnable,
} from "./services/settings";
// import AsyncSearch from "./core/search/async/asyncSearch";
import AsyncUploadCV from "./core/uploadcv/async/asyncUploadCV";
import RespScreen from "./core/resp/page/respScreen";
import AsyncAdminAgencies from "./core/admin/async/asyncAdminAgencies";
import AsyncSearchUser from "./core/search/async/asyncSearchUser";
import AsyncVideoTutorial from "./core/tutorial/async/asyncVideoTutorial";
import FbInfo from "./core/test/page/fbInfo";
// import AsyncEmailUserList from "./core/admin/async/asyncEmailUserList";
import AsyncTerms from "./core/terms/async/asyncTerms";
// import WelcomePageGoogle from "./core/welcomegoogle/page/welcomePage";
import { isInAppBrowserV2, redirectToBrowser } from "./utils/redirectbrowser";
import Logo from "./core/account/component/logo";
import RegisterEmailForm from "./core/account/page/registerEmail";
import RegisterIdentityForm from "./core/account/page/registerIdentity";
import { getCurrentUser } from "./services/user";
import AsyncViewAllPostsGuest from "./core/quickpost/async/asyncViewAllPostsGuest";
import AsyncViewAgencyAllJobPostsGuest from "./core/quickpost/async/asyncViewAgencyAllJobPostsGuest";
import { isVerifiedProvider } from "./services/authprovider";
import { logEvent } from "./services/log";
import AsyncInterview from "./core/interview/async/asyncInterview";
// import WelcomePageYahoo from "./core/welcomeyahoo/page/welcomePage";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
      showMessage: false,
    };
  }

  componentDidMount() {
    if (isInAppBrowserV2()) {
      this.setState({ showMessage: true });
      redirectToBrowser();
    } else {
      this.authListener();
    }
  }

  authListener() {
    this.unsubscribe = fire.auth().onAuthStateChanged(async (user) => {
      if (user) {
        //get the current user to identify
        const curUser = await getCurrentUser();
        // console.log(curUser);
        const provider = curUser.providerData[0].providerId || "";
        const userid = user.uid;
        const email = user.email;
        //check if firebase exists, then get the user, else, create a new user in firestore
        const userDoc = await fire
          .firestore()
          .collection("users")
          .doc(userid)
          .get();

        const uniqNum = new Date().getTime();

        if (!userDoc.exists) {
          //FIRST TIME HERE, CREATE A NEW USER
          const verifiedProvider = isVerifiedProvider(provider);

          const userObj = {
            createdusing: provider,
            loggedinusing: provider,
            emailContactable: verifiedProvider,
            email,
            fullname: "",
            excludefromemail: true,
            background: 7,
            created: uniqNum,
            userid: userid,
            lastupdated: uniqNum,
            lastverification: verifiedProvider ? uniqNum : 0,
          };
          //if verifiedProvider, meaning there is an email handler that automatically sends them welcome email
          //which means, we need to mark this with lastemailrecieved and lastemailsubject
          if (verifiedProvider) {
            userObj.lastemailreceived = uniqNum;
            userObj.lastemailsubject =
              "Welcome Aboard! ⚓ Take Your Seafarer Career to the Next Level";
          }
          //if user is !verified, admin will manually send welcome email to them
          await fire.firestore().doc(`users/${userid}`).set(userObj);
          //async send email if not verified provider
          if (!verifiedProvider) {
            console.log(`Sending verification email...`);

            curUser
              .sendEmailVerification()
              .then(() => {
                console.log("Verification email sent!");
                logEvent("send_email_verification_success", {
                  email: curUser.email,
                });
              })
              .catch((error) => {
                console.error("Error sending verification email:", error);
                logEvent("send_email_verification_fail", {
                  email: curUser.email,
                  message: error.message,
                });
              })
              .finally(() => {
                window.location.href = "/registeridentity";
              });
          } else {
            // If provider is already verified (like Google sign-in)
            // Just redirect immediately
            window.location.href = "/registeridentity";
          }
        } else {
          //data already exists
        }
        this.setState({ user, loading: false });
      } else {
        this.setState({ user: null, loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.setState({ showMessage: false });
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { user, loading } = this.state;

    if (this.state.showMessage) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            zIndex: 10000,
            textAlign: "center",
          }}>
          <div className="py-4">
            <div className="text-center container h-50 pb-4 mb-0">
              <Logo size={50} />
              <h1 className="mb-0 pb-0 font-weight-bold text-primary pointer">
                {getWebsitePrefix()}
                <span className="text-muted font-weight-normal">
                  {getWebsiteSuffix()}
                </span>
              </h1>
              <p className="text-muted">Alpha v{getWebVersion()}</p>
            </div>
            <div className="pb-4">Opening in Google Chrome...</div>
            <div className="pb-4">
              If this doesn't work, please open www.seamanbio.ph in Google
              Chrome.
            </div>
            <div className="pb-4">
              If you still have trouble, contact jeyner@seamanbio.ph
            </div>
            <button
              className="font-weight-bold btn btn-block btn-lg btn-primary roundcornernocolorbutton"
              onClick={() => {
                this.setState({ showMessage: true });
                redirectToBrowser();
              }}>
              Continue
            </button>
          </div>
        </div>
      );
    }
    if (loading === true) {
      return <LoadingSpinner />;
    }

    return (
      <React.Fragment>
        <Switch>
          {/*
          NO LOGIN REQUIRED
          */}

          <Route
            path="/hiring/:selectedrank"
            render={(props) => {
              if (!user) {
                return <AsyncViewAllPostsGuest {...props} user={user} />;
              }
              return <AsyncViewAllPosts {...props} user={user} />;
            }}
          />
          <Route
            path="/hiring"
            render={(props) => {
              if (!user) {
                return <AsyncViewAllPostsGuest {...props} user={user} />;
              }
              return <AsyncViewAllPosts {...props} user={user} />;
            }}
          />
          <Route
            path="/jobposts/:selectedrank"
            render={(props) => {
              if (!user) {
                return <AsyncViewAllPostsGuest {...props} user={user} />;
              }
              return <AsyncViewAllPosts {...props} user={user} />;
            }}
          />

          <Route
            path="/jobposts"
            render={(props) => {
              if (!user) {
                return <AsyncViewAllPostsGuest {...props} user={user} />;
              }
              return <AsyncViewAllPosts {...props} user={user} />;
            }}
          />
          <Route
            path="/privacy"
            render={(props) => {
              return <AsyncPrivacy {...props} user={user} />;
            }}
          />
          <Route
            path="/terms"
            render={(props) => {
              return <AsyncTerms {...props} user={user} />;
            }}
          />
          <Route
            path="/tutorial"
            render={(props) => {
              return <AsyncVideoTutorial {...props} user={user} />;
            }}
          />
          <Route
            path="/howtoapply"
            render={(props) => {
              return <AsyncVideoTutorial {...props} user={user} />;
            }}
          />
          <Route
            path="/welcome/:refid"
            render={(props) => {
              if (!user) {
                return <WelcomePage {...props} user={user} />;
              }
              return <AsyncMainPage {...props} user={user} />;
            }}
          />

          <Route
            path="/resp/:freq/:actor"
            render={(props) => {
              return <RespScreen {...props} user={user} />;
            }}
          />
          <Route
            path="/welcome"
            render={(props) => {
              if (!user) {
                return <WelcomePage {...props} user={user} />;
              }
              return <AsyncMainPage {...props} user={user} />;
            }}
          />

          {/* <Route
            path="/welcomegoogle"
            render={(props) => {
              if (!user) {
                return <WelcomePageGoogle {...props} user={user} />;
              }
              return <AsyncMainPage {...props} user={user} />;
            }}
          />
          <Route
            path="/welcomeyahoo"
            render={(props) => {
              if (!user) {
                return <WelcomePageYahoo {...props} user={user} />;
              }
              return <AsyncMainPage {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/auth"
            render={(props) => {
              return <AsyncAuthHandler {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/register/:refid"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterForm {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/register"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              // return <RegisterForm {...props} user={user} />;
              return <RegisterEmailForm {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/registeremail"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <RegisterEmailForm {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/testimonials"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              return <AsyncTestimonials {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          {/*  
          <Route
            path="/magsaysay"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              if (!isGlobalPublicPageEnable())
                return <Redirect to="/home" />;
              return <AsyncViewAgencyAllJobPosts {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/forgot/:refid"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/forgot"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncForgotPassword {...props} user={user} />;
            }}
          />
          <Route
            path="/login/:refid"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncLoginForm {...props} user={user} />;
            }}
          />
          <Route
            path="/login"
            render={(props) => {
              if (user) {
                return <Redirect to="/home" />;
              }
              return <AsyncLoginForm {...props} user={user} />;
            }}
          />

          <Route
            path="/account/created"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncRegisterSuccess {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/account/setup"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncAccountSetup {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/account/edit"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncAccountEdit {...props} user={user} />;
            }}
          />

          <Route
            path="/profile"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <AsyncProfile {...props} user={user} />;
            }}
          />
          <Route
            path="/upload"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              return <AsyncUploadCV {...props} user={user} />;
            }}
          />
          {/* <ProtectedRoute component={AsyncMainPage} path="/home" user={user} /> */}

          <Route
            path="/agencies"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <AsyncAgencyList {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/search"
            render={(props) => {
              if (!user) {
                return <Redirect to="/welcome" />;
              }
              // else {
              //   if (!user.emailVerified) {
              //     return <AccountVerify {...props} user={user} />;
              //   }
              // }
              return <AsyncSearch {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/ctrl/roles"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncRolesSetup {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/fbgroups"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncFBGroups {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/agencies"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncAdminAgencies {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/userlist"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncUserList {...props} user={user} />;
            }}
          />
          {/* <Route
            path="/ctrl/emailuserlist"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncEmailUserList {...props} user={user} />;
            }}
          /> */}
          <Route
            path="/ctrl/userstats"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncUserStats {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/fbinfo"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <FbInfo {...props} user={user} />;
            }}
          />
          <Route
            path="/ctrl/invalidusers"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncInvalidUserList {...props} user={user} />;
            }}
          />
          <ProtectedRoute
            component={RegisterIdentityForm}
            path="/registeridentity"
            user={user}
          />
          <ProtectedRoute
            component={AsyncDashboard}
            path="/dashboard"
            user={user}
          />
          <ProtectedRoute
            component={AsyncPost}
            path="/ctrl/jobpost"
            user={user}
          />
          <ProtectedRoute
            component={AsyncPostJobs}
            path="/ctrl/postjobs"
            user={user}
          />
          <ProtectedRoute
            component={AsyncSearchUser}
            path="/ctrl/search"
            user={user}
          />
          <ProtectedRoute
            component={AsyncViewApplications}
            path="/ctrl/viewapplications/:jobid"
            user={user}
          />
          <Route
            path="/ctrl"
            render={(props) => {
              if (!user) {
                return <Redirect to="/login" />;
              } else {
                // if (!user.emailVerified) {
                //   return <AccountVerify {...props} user={user} />;
                // }
              }
              return <AsyncAdminDashboard {...props} user={user} />;
            }}
          />

          <ProtectedRoute
            component={AsyncInterview}
            path="/interview"
            user={user}
          />
          <ProtectedRoute component={AsyncMainPage} path="/home" user={user} />
          {/* <ProtectedRoute
            component={AsyncUserProfile}
            path="/home"
            user={user}
          /> */}
          <ProtectedRoute component={AsyncTravel} path="/travels" user={user} />

          <Route
            path="/:agency"
            render={(props) => {
              // if (user) return <Redirect to="/home" />;
              if (!isGlobalPublicPageEnable()) return <Redirect to="/home" />;
              if (!user) {
                return (
                  <AsyncViewAgencyAllJobPostsGuest {...props} user={user} />
                );
              }
              return <AsyncViewAgencyAllJobPosts {...props} user={user} />;
              // return <AsyncRegisterForm {...props} user={user} />;
            }}
          />
          <Redirect from="/" exact to="/welcome" />
          <Route
            path="*"
            render={(props) => {
              if (!user) {
                return <AsyncPageNotFound />;
              }
              return <AsyncPageNotFound {...props} user={user} />;
            }}
          />
          <Route
            path="/404"
            render={(props) => {
              if (!user) {
                return <AsyncPageNotFound />;
              }
              return <AsyncPageNotFound {...props} user={user} />;
            }}
          />
        </Switch>
        <div className="clearfix my-4">&nbsp;</div>
      </React.Fragment>
    );
  }
}

export default App;
