import {
  isAgencySuperAdminRole,
  isSuperAdminRole,
  isSystemAdminRole,
  isSystemFBPosterRole,
} from "../../roles/service/roles";

export const BIGQUERY_STRING = "bq";
export const MONGODB_STRING = "mongodb";

export async function showAdminMenu(user) {
  if (
    (await isSystemAdminRole(user)) ||
    (await isSuperAdminRole(user)) ||
    (await isSystemFBPosterRole(user)) ||
    (await isAgencySuperAdminRole(user))
  ) {
    return true;
  }

  return false;
}

export function getActiveAskQuery() {
  // return BIGQUERY_STRING;
  return MONGODB_STRING;
}
