import React from "react";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { emojisplosion } from "emojisplosion";

function StatCounter({ settings, animate = false }) {
  return settings ? (
    <Row className="">
      <Col xs={6} md={3} className="px-0 mx-0 pb-4">
        <div
          onClick={(event) => {
            const x = event.clientX;
            const y = event.clientY;
            emojisplosion({
              // emojis: ["🏀"],
              position() {
                return {
                  x: x,
                  y: y,
                };
              },
            });
          }}
          className="pointer">
          <div>
            <span
              role="img"
              aria-label="applications"
              className=""
              style={{ userSelect: "none", fontSize: "30px" }}>
              📤
            </span>
          </div>
          <div className="font-weight-bold " style={{ fontSize: "30px" }}>
            {animate ? (
              <CountUp end={settings?.applications} />
            ) : (
              settings?.applications
            )}
          </div>
          <div className="text-muted font-weight-bolds">Applications</div>
        </div>
      </Col>
      <Col xs={6} md={3} className="px-0 mx-0 pb-4">
        <div
          onClick={(event) => {
            const x = event.clientX;
            const y = event.clientY;
            emojisplosion({
              // emojis: ["☀️"],
              position() {
                return {
                  x: x,
                  y: y,
                };
              },
            });
          }}
          className="pointer">
          <div>
            <span
              role="img"
              aria-label="users"
              className=""
              style={{ userSelect: "none", fontSize: "30px" }}>
              👦🏻👩🏻
            </span>
          </div>
          <div className="font-weight-bold " style={{ fontSize: "30px" }}>
            {animate ? <CountUp end={settings?.members} /> : settings?.members}
          </div>
          <div className="text-muted font-weight-bolds">Members</div>
        </div>
      </Col>
      <Col xs={6} md={3} className="px-0 mx-0 pb-4">
        <div
          onClick={(event) => {
            const x = event.clientX;
            const y = event.clientY;
            emojisplosion({
              // emojis: ["🌟"],
              position() {
                return {
                  x: x,
                  y: y,
                };
              },
            });
          }}
          className="pointer">
          <div>
            <span
              role="img"
              aria-label="agencies"
              className=""
              style={{ userSelect: "none", fontSize: "30px" }}>
              🏬
            </span>
          </div>
          <div className="font-weight-bold " style={{ fontSize: "30px" }}>
            {animate ? (
              <CountUp end={settings?.agencies} />
            ) : (
              settings?.agencies
            )}
          </div>
          <div className="text-muted font-weight-bolds">Agencies</div>
        </div>
      </Col>

      <Col xs={6} md={3} className="px-0 mx-0 pb-4">
        <div
          onClick={(event) => {
            const x = event.clientX;
            const y = event.clientY;
            emojisplosion({
              // emojis: ["🏀"],
              position() {
                return {
                  x: x,
                  y: y,
                };
              },
            });
          }}
          className="pointer">
          <div>
            <span
              role="img"
              aria-label="jobs"
              className=""
              style={{ userSelect: "none", fontSize: "30px" }}>
              🔥
            </span>
          </div>
          <div className="font-weight-bold " style={{ fontSize: "30px" }}>
            {animate ? <CountUp end={settings?.jobs} /> : settings?.jobs}
          </div>
          <div className="text-muted font-weight-bolds">Job Posts</div>
        </div>
      </Col>
    </Row>
  ) : null;
}

export default StatCounter;
