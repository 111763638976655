import React from "react";
import loadable from "@loadable/component";
import NavBarWithSpinner from "../../common/navBarwithSpinner";

function AsyncInterview({ ...rest }) {
  const AsyncLoad = loadable(() => import("../page/interview"));
  return <AsyncLoad {...rest} fallback={<NavBarWithSpinner />} />;
}

export default AsyncInterview;
