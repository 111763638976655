export const MASTER = "Master";
export const CHIEF_MATE = "Chief Mate";
export const SECOND_MATE = "2nd Mate";
export const THIRD_MATE = "3rd Mate";
export const FOURTH_MATE = "4th Mate";
export const CHIEF_ENGR = "Chief Engr";
export const SECOND_ENGR = "2nd Engr";
export const THIRD_ENGR = "3rd Engr";
export const FOURTH_ENGR = "4th Engr";
export const ETO = "ETO";
export const ELECTRICIAN = "Electrician";
export const OILER = "Oiler";
export const FITTER = "Fitter";
export const WIPER = "Wiper";
export const BOSUN = "Bosun";
export const COOK = "Cook";
export const OTHERS = "Others";
export const AGENCY_ADMIN = "AGENCY ADMIN";
export const TOP_RANKS = "Top 4";
export const ALL = "All";

export const MASTER_TAG = "master";
export const CHIEF_MATE_TAG = "co";
export const SECOND_MATE_TAG = "2o";
export const THIRD_MATE_TAG = "3o";
export const FOURTH_MATE_TAG = "4o";
export const CHIEF_ENGR_TAG = "ce";
export const SECOND_ENGR_TAG = "2e";
export const THIRD_ENGR_TAG = "3e";
export const FOURTH_ENGR_TAG = "4e";
export const ETO_TAG = "eto";
export const ELECTRICIAN_TAG = "electrician";
export const OILER_TAG = "oiler";
export const FITTER_TAG = "fitter";
export const WIPER_TAG = "wiper";
export const BOSUN_TAG = "bosun";
export const COOK_TAG = "cook";
export const CCK_TAG = "cc";
export const AGENCY_ADMIN_TAG = "agency_admin";

export const OFFICER_TAG = "officer";
export const ELECTRICAL_TAG = "electrical";
export const PRIORITY_TAG = "prioacct";

const positions = [
  //seafarer ranks or positions
  "Master",
  "Chief Officer",
  "2nd Officer",
  "3rd Officer",
  // "4th Officer",
  "Deck Officer",
  "Junior Officer",
  "Chief Engineer",
  "2nd Engineer",
  "3rd Engineer",
  "4th Engineer",
  "Gas Engineer",
  // "Junior 4th Engineer",
  "1st Assistant Engineer",
  "2nd Assistant Engineer",
  "3rd Assistant Engineer",
  "Engine Officer",
  "ETO/ETR",
  "Electrician",
  "Electrical Engineer",
  "Oiler",
  "Fitter",
  "Welder",
  "Chief Cook",
  "Chef",
  "Cook",
  "Messman",
  "Wiper",
  "Rigger",
  "Ordinary Seaman",
  "Able Seaman",
  "Motorman",
  "Pumpman",
  "Bosun",
  "Cadet",
  "Deck Cadet",
  // "Engine Cadet",
  "Electrical Cadet",
  // "Galley Cadet",
  "Reefer",
  "Assistant Reefer",
  "Mechanic",
  "Operator",
  "Repairman",
  // "Bar Tender",
  "Painter",
  "Steward",
  "Tailor",
  "Waiter",
  "Manager",
  "Others",
  "Full Crew",
];

const positionsv1 = [
  //seafarer ranks or positions
  "Master",
  "Chief Officer",
  "2nd Officer",
  "3rd Officer",
  // "Deck Officer",
  "Junior Officer",
  "Chief Engineer",
  "2nd Engineer",
  "3rd Engineer",
  "4th Engineer",
  "Gas Engineer",
  "Junior Engineer",
  "1st Assistant Engineer",
  "2nd Assistant Engineer",
  "3rd Assistant Engineer",
  // "Engine Officer",
  "ETO/ETR",
  "Electrician",
  "Electrical Engineer",
  "Oiler",
  "Fitter",
  "Welder",
  "Chief Cook",
  "Chef",
  "Cook",
  "Messman",
  "Wiper",
  "Rigger",
  "Ordinary Seaman",
  "Able Seaman",
  "Motorman",
  "Pumpman",
  "Bosun",
  "Cadet",
  // "Deck Cadet",
  // "Electrical Cadet",
  "Reefer",
  // "Assistant Reefer",
  "Mechanic",
  "Operator",
  "Repairman",
  "Painter",
  "Steward",
  // "Tailor",
  "Waiter",
  // "Manager",
  "Others",
  // "Full Crew",
];

const positionsinterview = [
  //seafarer ranks or positions
  "Master",
  "Chief Officer",
  "2nd Officer",
  "3rd Officer",
  "Junior Officer",
  "Chief Engineer",
  "2nd Engineer",
  "3rd Engineer",
  "4th Engineer",
  "Gas Engineer",
  "Junior Engineer",
  "1st Assistant Engineer",
  "2nd Assistant Engineer",
  "3rd Assistant Engineer",
  "ETO",
  "ETR",
  "Electrician",
  "Oiler",
  "Fitter",
  "Welder",
  "Chief Cook",
  "Chef",
  "Cook",
  "Messman",
  "Wiper",
  "Rigger",
  "Ordinary Seaman",
  "Able Seaman",
  "Motorman",
  "Pumpman",
  "Bosun",
  "Deck Cadet",
  "Engine Cadet",
  "Electrical Cadet",
  "Reefer",
  "Mechanic",
  "Repairman",
  "Painter",
  "Steward",
  "Waiter",
];

export function getSeamanPositions() {
  return positions;
}

export function getMapSeamanPositions() {
  return positions.map((position) => {
    return { _id: position, name: position };
  });
}

export function getMapSeamanPositionsV1() {
  return positionsv1.map((position) => {
    return { _id: position, name: position };
  });
}

export function getMapSeamanPositionsForInterview() {
  return positionsinterview.map((position) => {
    return { _id: position, name: position };
  });
}

export function getRawMapSeamanPositionsV1() {
  return positionsv1;
}

const data = [
  "3rd engineer - must have bulk experience",
  "eto - must have bulk experience",
  "chiefmate - must have bulk experience",
  "master - must have bulk experience",
  "refrigeration mechanic - must have passenger vessel experience",
  "asst. electrician with etr - must have passenger vessel experience",
  "deck repairman - must have passenger vessel experience",
  "engine repairman - must have passenger vessel experience",
  "1st repairman - must have passenger vessel experience",
  "(5x) ab forklift crane operator - experienced ab/ forklift driver / crane operator with certificates",
  "(2x) 3rd engineer - with previous experience from any offshore vessels",
  "chief cook",
  "4th engineer x2 asap - at least 1 yr in rank, centrifugal pump",
  "3rd officer - with atot & atct  cop, at least 1 yr in rank, centrifugal pump",
  "eto - at least 2 yrs in rank",
  "master - at least 2 yrs in rank",
  "bosun",
  "oiler",
  "electrician / eto - bargain accepted",
  "chief officer",
  "electrician / eto",
  "3rd engineer",
  "2nd engineer",
  "chief engineer",
  "ordinary seaman",
  "4th engineer",
  "3rd officer",
  "2nd officer",
  "master ",
  "electrician / eto - with crane experience",
  "chief officer ",
  "master - with ice class experience needed",
  "chief officer - with marflex pump experience needed",
  "fitter",
  "elect. (eto)",
  "chief engineer ",
  "master",
  "electrician (eto or etr) - with me engine experience",
  "2nd engineer - urgent! (with macgregor crane experience, with man b&w mc 2 strokes engine)",
  "cook",
  "motorman",
  "able seamen",
  "3rd engineer - must have psv vessel experience",
  "2nd engineer - must have psv vessel experience",
  "chief engineer - must have psv vessel experience",
  "2nd officer - must have psv vessel experiences",
  "chief officer - must have psv vessel experiences",
  "able seaman (2)",
  "oiler (4)",
  "fitter (3)",
  "4th engineer (1) - at least 1 year or more exp. in latest rank at latest sign-off agency",
  "3rd engineer (1)",
  "2nd engineer (3)",
  "3rd mate",
  "2nd mate",
  "elect eto / etr (3)",
  "chief mate",
  "able bodied seaman (ab)",
  "deck officer (major patron)",
  "full crew - looking for engine officers with rta experience",
  "trainee fitter",
  "trainee eto",
  "eto",
  "junior officer",
  "3x oiler",
  "5x ab forklift",
  "3x chief cook",
  "2nd engineer (3) - bulk / container; at least 1 yr or more experience in latest rank at latest sign-off",
  "fitter (2) - bulk / container ",
  "(x2) catos - ii/4 cert. & nc1 cert.",
  "(x4) oiler",
  "(x18) able seaman",
  "(x3) chief cook - indian & european cuisine",
  "(x3) fitter",
  "(x4) eto",
  "(x2) 3rd mate",
  "(x3) 4th engineer",
  "(x2) 2nd engineer",
  "x6) chief cook - indian cuisine",
  "(x4) bosun",
  "x3) etr - with crane experience",
  "(x4) 3rd engineer - with us visa",
  "(x3) 2nd mate - with us visa",
  "(x8) 2nd engineer - me/mc",
  "(x6) chief mate - log/handy/pana",
  "chiefmate",
  "cadet fitter",
  "cadet electrician",
  "abled bodied seaman (ab)",
  "2nd engineer (minimum 2.5 yrs of experience as 2e, valid and complete stcw certificates)",
  "chief engineer (with valid us visa) ",
  "electrician - eto/etr license",
  "3rd engineer (with valid us visa)",
  "fitter (2)",
  "4th engineer (2)",
  "3rd mate (2)",
  "3rd engineer (2)",
  "able seaman",
  "wiper (must have an oilchem experience)",
  "(6) engine fitter",
  "(6) eto/etr",
  "(2) 4th engineer",
  "(2) 3rd engineer",
  "(4) able seaman",
  "(3) bosun",
  "(2) 2nd officer",
  "etr (1)",
  "2nd engineer (1) with me experience",
  "chief officer (1)",
  "chief officer (1) with stainless steel experience",
  "eto/etr (5) with crane experience",
  "3rd officer (2)",
  "2nd engineer (2)",
  "chief engineer (2)",
  "3rd engineer (must have an oilchem experience)",
  "2nd asst. engineer",
  "1st asst engineer (3x)",
  "(2x) master",
  "master mariner",
  "elec. (eto)",
  "(2x) 3rd engineer - with previous experience from any offshore vessel ",
  "(3) eto - project site will be in europe",
  "1st asst. engineer",
  "dec fitter",
  "3rd asst. engineer",
  "oiler #1",
  "elec (etr)",
  "1st asst. enginer",
  "(1x) eto/etr (iii/6 or iii/7, at least 1 year experience offshore vessel",
  "oiler (x2)",
  "electro-technical officer (x3)",
  "fourth engineer (x4)",
  "third engineer (x2)",
  "second engineer (with a valid us visa)",
  "third officer (x3)",
  "2nd officer (x2)",
  "(3) etr",
  "(3) eto",
  "chief cook with korean cuisine experience",
  "electro technical ratings (etr)",
  "electro technical officer (eto)",
  "third engineer",
  "second engineer",
  "(2) third officer",
  "(1) dozer operator",
  "(1) third officer",
  "(1) 2nd officer",
  "eto (me-b/me-c experience)",
  "2nd engineer (me-b/me-c experience)",
  "chief engineer (me-b/me-c experience)",
  "chief officer (handymax, panamax or capesize experience)",
  "master (handymax, panamax or capesize experience)",
  "chief mate (1) - bulk / gear type",
  "2nd officer (1) - at least 1 year or more experience in latest rank at latest sign-off agency",
  "oiler (4) - bulk / container",
  "4th engineer (1) - bulk / container",
  "3rd engineer (1) - bulk / container",
  "electrician (3) - gear type",
  "bosun (1) - bulk / gear type experience",
  "fitter (2) - bulk / container",
  "chief cook (with experience in both european & indian cuisines)",
  "chief officer (handysize & panamax experience)",
  "messman",
  "able seaman ",
  "pumpman",
  "2nd cook",
  "trainee fitter (must have cable ship experience)",
  "ordinary seaman (must have cable ship experience)",
  "4th engineer (must have cable ship experience)",
  "chief engineer (urgent!)",
  "(3) able seaman",
  "(3) fitter - with at least 2 contracts  or more experience on-board vessel international in latest agency with mix crew european experience",
  "(3) oiler",
  "(2x) 3rd engineer  - with previous experience from any offshore vessels, travel documents and certificates on hand",
  "(3) 2nd engineer",
  "(4) chief officer",
  "(1) master",
  "(3) fitter - preferably with us visa",
  "(5) electrician - with etr/eto license",
  "(1) 2nd engineer",
  "(2) chief engineer",
  "(6) chief officer",
  "(3) master",
  "(2) elec cadet/assistant elec - with etr license",
  "(2) eto",
  "(2) 2nd engineer",
  "2nd officer (with general cargo vessel experience, experience from uk ports is an advantage)",
  "chief officer (with general cargo vessel experience, experience from uk ports is an advantage)",
  "3rd engineer (experience preferably from offshore dp vessels, with valid us b1ocs visa)",
  "ordinary seaman (must have container experience)",
  "eto (must have m.e and bulk experience)",
  "ordinary seaman (with valid us visa)",
  "oiler / motorman",
  "elec. eto",
  "electrical engineer",
  "1st engineer",
  "asst. deck supervisor - 2x (offshore experience)",
  "eto - 3x (offshore experience)",
  "4th engineer - 2x (offshore experience) - crane ship",
  "3rd engineer - 3x (offshore experience) - crane ship",
  "2nd engineer - 2x (w/dpm cert or experience) - crane ship",
  "2nd mate dpo (sr. dpo or jr. dpo) - osv",
  "fitter - 2x (mar deployment)",
  "3rd engineer - 2x (urgent - mar deployment)",
  "2nd engineer - 2x (me eng experience)",
  "chief engineer - 2x (me eng experience)",
  "3rd officer - 2x",
  "2nd officer - 2x",
  "chief officer - 2x",
  "master - 2x",
  "2nd engineer - me eng",
  "chief engineer - me eng",
  "3rd officer - (female) - urgent",
  "2nd officer - (female) - urgent",
  "2nd officer - 2x (males) (mar-apr deployment)",
  "chief officer - 2x (mar-apr deployment)",
  "oiler - 2x (mar deployment) - urgent",
  "ab cook - 2x (mar deployment) - urgent",
  "3rd officer - 2x (mar deployment) - urgent",
  "tr. fitter - 5x (w/ domestic experience and shipyard experience)",
  "fitter - 5x (salary - 2200usd + ot + rejoining bonus) - with international vessel experience",
  "electrical officer (eto and etr)",
  "fitter - 5x",
  "3rd engr - 3x (with higher license, 35 yrs old below)",
  "eto - 3x (me eng experience) - (geared) - with signing bonus",
  "chief officer - 3x (mar-apr deployment) - (geared)",
  "chief engr - 3x (mar-apr deployment) - (panamax-me engine)",
  "master (mar-apr deployment) - geared",
  "electro-technical officer (eto)",
  "fourth engineer",
  "third officer",
  "chief cook (korean cuisine experience)",
  "electricians (eto/etr)",
  "3rd engineer (urgent)",
  "2nd officer (urgent)",
  "fitter - w/ iii-4 and iii-5 certificate",
  "chief cook (korean/chinese or european cuisine experience)",
  "electro-technical officer (eto) -urgent",
  "2nd engineer (urgent)",
  "e/e",
  "2/e",
  "wiper",
  "ab (2) - with log bulk experience",
  "eto (2) - with crane experience",
  "oiler (2)",
  "fitter (2) - with 3/5 or full pledged fitter experience",
  "2nd engineer (3) - with me engine experience",
  "chief engineer (8) - with me engine experience",
  "chief officer (8)",
  "5x ab excavator w/ timber",
  "5x ab excavator",
  "5x ab fitter",
  "3x 2nd mate",
  "5x chief mate",
  "5x chief engineer",
  "(2) fitter",
  "(2) electrician",
  "(2) thirdmate",
  "oiler (3) - at least 1 year or more experience in latest rank in current agency",
  "ce akto (must have valid ce license and must have extensive experience as 2e on gas tanker",
  "chief cook with european & chinese cuisine experience",
  "able seaman with us visa",
  "eto with iii/6",
  "fitter with iii/4",
  "2nd officer (ugent)",
  "os (3) - preferably with a valid us visa and international container vessel experience",
  "ab (5) - preferably with a valid us visa and international container vessel experience",
  "bosun (3) - preferably with a valid us visa and international container vessel experience",
  "fitter (7) - preferably with a valid us visa and international container vessel experience",
  "eto/etr (5) - preferably with a valid us visa and international container vessel experience",
  "chief cook (2) - preferably with a valid us visa and international container vessel experience",
  "4th engineer (2) - preferably with a valid us visa and international container vessel experience",
  "3rd engineer (2) - preferably with a valid us visa and international container vessel experience",
  "3rd officer (2) - preferably with a valid us visa and international cntainer vessel experience",
  "2nd officer (2) - preferably with a valid us visa international container vessel experience",
  "fitter (with valid us visa)",
  "eto (with valid us visa)",
  "2nd engineer (with valid us visa)",
  "chief officer (with valid us visa)",
  "third engineer (urgent)",
  "second engineer (urgent)",
  "fitter (with 3/4, 3/5 and smaw training certificate, at least 8-10 mos. experience in rank)",
  "chief engineer (must have 12-24 mos. experience in rank)",
  "chief engineer (1)",
  "chief mate (3)",
  "eto (5)",
  "second engineer (5)",
  "chief engineer (5)",
  "chief mate (5)",
  "master (5)",
  "ab",
  "captain",
  "pumpman - must have oilchem exp",
  "3rd engr - must have oilchem exp",
  "c/o (x2)",
  "2/e (x2)",
  "c/e (x3)",
  "master (x2)",
  "fitter (must have us visa with 2 years validity upon application)",
  "engine fitter",
  "eto/etr",
  "eto/etr (eto iii/6 or etr iii/7 licensed)",
  "3x ab fitter",
  "3x cm",
  "5x ce",
  "eto (me/mc experience an advantage)",
  "2e (me/mc experience an advantage)",
  "ce (me/mc experience an advantage)",
  "eto (must have bulk and m.e experience)",
  "3rd engineer (must have bulk and m.e experience)",
  "eto (must have asd experience)",
  "2nd engineer (must have asd experience)",
  "chief officer (must have asd experience)",
  "chief engineer (must have asd experience)",
  "master (must have asd experience)",
  "chief cook (with experience in both european & indian cuisines, preferably with valid us visa)",
  "msm (preferably with us visa)",
  "fitter (preferably with us visa)",
  "g/e (preferably with us visa)",
  "1ae (preferably with us visa)",
  "chief officer (preferably valid us visa)",
  "electro technical officer",
  "wiper (preferably with us visa)",
  "mtm (preferably with us visa)",
  "o/s (preferably with us visa)",
  "a/b (preferably with us visa)",
  "e/e (preferably with us visa)",
  "1/ae (preferably with us visa)",
  "chief engineer (preferably with us visa)",
  "3rd officer (preferably with us visa)",
  "chief officer (preferably with us visa)",
  "1 ae",
  "chief officer (with us visa)",
  "3rd engineer ",
  "(5) eto / etr",
  "(3) fitter",
  "(3) 3rd engineer",
  "(11) chief engineer",
  "(13) chief officer",
  "(5) master",
  "eto/etr (with experience in the same capacity for at least 2 years)",
  "waiter, bartender (french speaker, min. 1 year experience)",
  "chef de partie, 1st chef, 3rd chef ",
  "painter (10)",
  "3rd engineer (preferably with valid us b1ocs visa)",
  "eto/ etr (3) ",
  "a/b (3)",
  "oiler (3)",
  "stewardess",
  "3eng, 4eng, geng",
  "os, motorman",
  "electrician (6)",
  "os (3) with valid us visa and international container vessel experience",
  "ab (5) - with valid us visa and international container vessel experience",
  "bosun (3) - with valid us visa and international container vessel experience",
  "fitter (7) - with valid us visa and international container vessel experience",
  "eto / etr (5) - with valid us visa and international container vessel experience",
  "4th engineer (2) - with valid us visa and international container vessel experience",
  "3rd engineer (2) - with valid us visa and international container vessel experience",
  "3rd officer (2) - with valid us visa and international container vessel experience",
  "2nd officer (2) - with valid us visa and international container vessel experience",
  "refrigeration mechanic (must have passenger experience)",
  "asst. electrician w/ etr (must have passenger experience)",
  "1st repairman, engine repairman, deck repairman (must have passenger experience)",
  "second engineer (2) - at least 12 months experience on rank and vessel",
  "chief mate (1) - at least 12 months experience on rank and vessel",
  "chief officer (1) - at least 12 months experience on rank and vessel",
  "wiper (must have tanker experience and valid c1d us visa)",
  "pumpman (must have tanker experience and valid c1d us visa)",
  "(2) fitter, (3) engine fitter",
  "(4) engine fitter",
  "3rd engineer (with us visa)",
  "(𝟐) 𝐅𝐢𝐭𝐭𝐞𝐫 with 3/ 5 or full pledged fitter",
  "𝐂𝐡𝐢𝐞𝐟 𝐄𝐧𝐠𝐢𝐧𝐞𝐞𝐫 with me engine and scrubber experience",
  "electrician (eto/etr) with or without us visa",
  "𝟐𝐧𝐝 𝐄𝐧𝐠𝐢𝐧𝐞𝐞𝐫 with me engine experience",
  "etr",
  "5x chief officer",
  "3x master",
  "cadet",
  "chief engineer, 3rd engineer, 4th engineer",
  "chief engineer, 2nd engineer, 3rd engineer, electrician",
  "master, chief officer, 2nd officer, 3rd officer, fitter",
  "cook (must have shipboard or 3-5 star hotel experience with chinese cuisine expertise)",
  "3rd engineer (must have m.e and bulk experience)",
  "oiler, fitter, able seaman, chief cook",
  "2nd engineer, 3rd engineer, 4th engineer, eto",
  "2x 3rd engineer",
  "5x messman - with valid us visa",
  "5x os - with valid us visa",
  "5x ab - with valid us visa",
  "3x 3rd engineer - with valid us visa",
  "5x ce - man b&w",
  "5x cm - logbulk/supramax/panamax",
  "5x master - logbulk/supramax/panamax",
  "ab, 3rd engineer",
  "eto/etr, stewardess",
  "3rd engineer, eto/etr ",
  "master, co, ce, 2e, 3e, 2o, 3o, eto/etr, ab, olr, os, wiper, cck",
  "2nd off, 3rd off, 3rd engr, 4th engr, ab, oiler, os, messman",
  "ab, os - new principal",
  "eto - new principal",
  "(3) ab/cook - with general cargo/multipurpose vessel experience",
  "(10) able seaman - with general cargo/multipurpose vessel experience",
  "2x 2nd engineer - with me experience",
  "2x chief engineer - with me experience",
  "3 chief officer",
  "chief cook - korean",
  "ftr/cdt",
  "elect/cdt",
  "e.t.o",
  "electro-technical officer",
  "oiler (must have tugboat experience)",
  "able seaman (must have tugboat experience)",
  "master (must have tugboat experience)",
  "2ae, 3ae",
  "third mate ",
  "oiler (with us visa)",
  "asst. electricin w/ etr (must have passenger vessel experience)",
  "engine repairman (must have passenger vessel experience)",
  "1st repairman (must have passenger vessel experience)",
  "no. 1 oiler",
  "electrician / eto (6500 usd, full crew, hmo, rejoining bonus)",
  "3rd engineer (4500 usd, full crew, hmo, rejoining bonus)",
  "2nd engineer (9800 usd, full crew, hmo, rejoining bonus)",
  "electrician / eto (6500 uds, full crew, hmo, rejoining bonus)",
  "2nd officer (4500 usd, full crew, hmo, rejoining bonus)",
  "chief officer (9800 usd, full crew, hmo, rejoining bonus)",
  "chief engineer (13300 usd, full crew, hmo, rejoining bonus)",
  "master (13800 usd, full crew, hmo, rejoining bonus)",
  "chief cook (1800 usd, european cuisine, with valid us visa)",
  "3rd officer (3500 usd)",
  "2nd officer (3400 usd)",
  "electrician / eto (6000 usd, me experience)",
  "fitter (2000 usd)",
  "electrician / eto (5000 usd)",
  "2nd engineer (7300 usd, seniority bonus, me experience)",
  "2nd engineer (7000 usd)",
  "chief engineer (9000 usd)",
  "fitter (2200 usd)",
  "reffer engineer (negotiable salary)",
  "electrician / etr (5000 usd)",
  "2nd office (3700 usd)",
  "3rd engineer (3600 usd, seniority bonus, rejoining bonus)",
  "2nd engineer (7800 usd, seniority bonus, rejoining bonus)",
  "chief engineer (9500 usd, seniority bonus)",
  "chiefmate (with us visa)",
  "pumpman (with us visa)",
  "chief officer with us visa for ahts",
  "3rd officer (must have oil chem experience)",
  "chef tournant, chef gardemanger, pastry cook, cook sushi, cook teppanyaki, cook",
  "chief engineer, eto",
  "electrical officer (holder of eto iii/6 or etr iii/7 license)",
  "chief officer (holder of c/m ii/2 or master ii/2 license)",
  "2off • 3off • 3eng • 4eng • eto • fitter • chief cook • ab • os • oiler • wiper",
  "ftr, olr, cck, os",
  "chief cook, oiler, ordinary seaman",
  "1/ae, cck (with us visa)",
  "urgent! 2m, 3e, olr",
  "3rd engr., 4th engr., eto/etr, fitter, motorman, ab, os, chiefcook (european/indian)",
  "master, chief mate, chief engr. (me/mc), 2nd engr. (me/mc), 2nd mate, 3rd engr., 4th engr., eto or etr, oiler (with us visa), bosun (with us visa), chiefcook (indian cuisine)",
  "chief engineer (me-c, me-b ), 2ae , 3/engr., 3ae , 4/engr",
  "captain, chief engineer, chief officer, 2nd off, 3rd off",
  "2m, 3m, 1ae, 2ae, 3ae, ab, oiler",
  "2m",
  "chief engineer, 2nd engr, 3rd engr, 4th engr, eto/etr, fitter, oiler ",
  "captain, chief officer, 2nd officer, 3rd officer, jr. 3rd officer",
  "cadet electricians, cadet fitter",
  "master, chief officer, 2nd off, 3rd off, chief engr, 2nd engr, 3rd engr, eto/etr, fitter",
  "chief engr, 2nd engr, 3rd engr, eto, fitter",
  "master, chief officer, 2nd off, bosun, a/b cook, ab",
  "chief engr, 2nd engr, 3rd engr",
  "ab excavator, ab fitter, oiler",
  "3rd engineer, fitter",
  "eto, os",
  "master, cm, ce, 2e, 2m, 3e, 4e",
  "os, mess/stwd",
  "chief officer, chief engr, 2nd engr, 3rd engr, eto/etr",
  "2nd engineer, 3rd engineer, 4th engineer, electrician (eto/etr), fitter, oiler, able seaman",
  "2nd engr, 2nd off, 3rd engr, 3rd off, 4th engr, fitter,  eto/etr, oiler",
  "chief cook, ab, os, fitter",
  "chief engineer, 2nd engr, eto/etr, oiler",
  "captain, chief officer, 20ff, 30ff",
  "electrician (with iii/6)",
  "3m",
  "master, chief officer, chief engineer, 2nd engr, eto/etr, fitter",
  "2nd engineer with r-flex or me engine experience",
  "4th engineer, ordinary seaman",
  "master (panamax), eto (cape/panamax)",
  "3m, eto",
  "3ae",
  "chief officer, chief engr. with mc engine type experience, 2nd engr. with mc engine type experience, 3rd engr., oiler",
  "chief officer, 2nd engineer",
  "2nd officer, 2nd engineer, 3rd engineer, 4th engineer, eto, oiler, fitter",
  "ce, 2e, fitter",
  "os, oiler, fitter, 4x chief cook",
  "os, oiler, fitter",
  "eto, etr",
  "motorman, os, fitter",
  "ac fitter",
  "2nd engr., electrician, bosun, ab cook, oiler (gp ab)",
  "chief engineer, chief officer, 2nd officer, 3rd officer, 3rd engr/4th engr, ab/promotable os, ab excavator, ab cook",
  "4th engr, oiler, ordinary seaman",
  "2nd engr with rt flex engine experience",
  "master, chief officer, chief engineer, 2nd engr, 3rd engr, 4th engr, eto/etr with mc engine type experience, fitter",
  "3rd engr, 4th engr, eto, fitter, bosun, chief cook, ab, oiler, os, wiper",
  "able seaman, oiler, fitter,  os",
  "chief officer, 2nd engr, eto, 3rd engr, 4th engr, fitter",
  "2nd engineer, 3rd engineer",
  "3rd officer, 3rd engr, 4th engr, bosun, ab, oiler",
  "master, chief officer, chief engr., 2nd engr., 3rd engr., oiler",
  "fitters, tr. fitters, ab, oiler",
  "master, c/engr, chief officer, eto, 2o, 3rd engr",
  "chief officer, 2o, 3o, 3rd engr., 4th engr., fitter",
  "cm bulk exp., 2e, etr/eto",
  "master, chief officer, 2o, 3o, 1st asst. engr., 2nd asst. engr., 3rd asst. engr., as, oiler1/ftr, oiler, electrician (etr lic)",
  "2m, 3e, eto",
  "2e with me experience",
  "3/o, 3/e, bosun, fitter, ab, os, oiler, wiper",
  "chief officer, chief engr, 2nd engr, 3rd engr, eto",
  "chief officer, chief engr, 2nd engr, eto",
];

export function getDataPositions() {
  return data;
}

export function getMapPositionTags() {
  return {
    [MASTER_TAG]: "Master",
    [CHIEF_MATE_TAG]: "Chief Officer",
    [SECOND_MATE_TAG]: "2nd Officer",
    [THIRD_MATE_TAG]: "3rd Officer",
    [FOURTH_MATE_TAG]: "4th Officer",
    do: "Deck Officer",
    jo: "Junior Officer",
    [CHIEF_ENGR_TAG]: "Chief Engineer",
    [SECOND_ENGR_TAG]: "2nd Engineer",
    [THIRD_ENGR_TAG]: "3rd Engineer",
    [FOURTH_ENGR_TAG]: "4th Engineer",
    ge: "Gas Engineer",
    je: "Junior Engineer",
    "1ae": "1st Assistant Engineer",
    "2ae": "2nd Assistant Engineer",
    "3ae": "3rd Assistant Engineer",
    eo: "Engine Officer",
    [ETO_TAG]: "ETO/ETR",
    el: "Electrician",
    ee: "Electrical Engineer",
    [OILER_TAG]: "Oiler",
    [FITTER_TAG]: "Fitter",
    we: "Welder",
    cc: "Chief Cook",
    ch: "Chef",
    [COOK_TAG]: "Cook",
    me: "Messman",
    [WIPER_TAG]: "Wiper",
    ri: "Rigger",
    os: "Ordinary Seaman",
    ab: "Able Seaman",
    mo: "Motorman",
    pu: "Pumpman",
    [BOSUN_TAG]: "Bosun",
    ca: "Cadet",
    dc: "Deck Cadet",
    ec: "Engine Cadet",
    elc: "Electrical Cadet",
    gc: "Galley Cadet",
    re: "Reefer",
    ar: "Assistant Reefer",
    mech: "Mechanic",
    op: "Operator",
    rep: "Repairman",
    bt: "Bar Tender",
    pa: "Painter",
    st: "Steward",
    ta: "Tailor",
    wa: "Waiter",
    ma: "Manager",
    fc: "Full Crew",
  };
}

export function getMapTagPositions() {
  return Object.fromEntries(
    Object.entries(getMapPositionTags()).map(([key, value]) => [value, key])
  );
}
export function getMapTagPositionsV1() {
  return Object.fromEntries(
    Object.entries(getMapPositionTags()).map(([key, value]) => [value, key])
  );
}

export function isMaster(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;

  // Exclude specific terms
  if (bio.includes("master ele") || bio.includes("captain wait")) {
    return false;
  }

  return (
    bio.startsWith("master") ||
    bio.startsWith("sdpo w/ master") ||
    bio.startsWith("captain") ||
    bio.startsWith("capt")
  );
}

export function isChiefMate(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio === "c/o" ||
    bio === "co" ||
    bio.startsWith("cm") ||
    bio.startsWith("c/ o") ||
    bio.startsWith("c/ mate") ||
    bio.startsWith("c/mate") ||
    bio.startsWith("c/ off") ||
    bio.startsWith("c/off") ||
    bio.startsWith("chief mate") ||
    bio.startsWith("ch.off") ||
    bio.startsWith("chfmate") ||
    bio.startsWith("chiefmate") ||
    bio.startsWith("chief off") ||
    bio.startsWith("chiefoff")
  );
}

export function isSecondMate(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("2nd mate") ||
    bio.startsWith("2nd off") ||
    bio.startsWith("2ndoff") ||
    bio.startsWith("2/o") ||
    bio.startsWith("2m") ||
    bio.startsWith("2/ o") ||
    bio.startsWith("second off") ||
    bio.startsWith("secondmate") ||
    bio.startsWith("second mate")
  );
}

export function isThirdMate(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("3rd mate") ||
    bio.startsWith("3rdmate") ||
    bio.startsWith("3rd off") ||
    bio.startsWith("3/o") ||
    bio.startsWith("3m") ||
    bio.startsWith("3/ o") ||
    bio.startsWith("3 o") ||
    bio.startsWith("3rd.off") ||
    bio.startsWith("3rdoff") ||
    bio.startsWith("third off") ||
    bio.startsWith("thirdmate") ||
    bio.startsWith("third mate")
  );
}

export function isFourthMate(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("4th mate") ||
    bio.startsWith("4th off") ||
    bio.startsWith("4rth off") ||
    bio.startsWith("4o") ||
    bio.startsWith("4m") ||
    bio.startsWith("4/o") ||
    bio.startsWith("4/ o") ||
    bio.startsWith("fourth off") ||
    bio.startsWith("fourth officer") ||
    bio.startsWith("fourth mate")
  );
}

export function isChiefEngr(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("chief eng") ||
    bio.startsWith("chief marine eng") ||
    bio.startsWith("ce") ||
    bio.startsWith("c/ eng") ||
    bio.startsWith("c/e")
  );
}

export function isSecondEngr(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("2nd eng") ||
    bio.startsWith("2/e") ||
    bio.startsWith("2e") ||
    bio.startsWith("sec eng") ||
    bio.startsWith("second eng")
  );
}

export function isThirdEngr(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("3rd eng") ||
    bio.startsWith("3/e") ||
    bio.startsWith("3rd/ eng") ||
    bio.startsWith("3e") ||
    bio.startsWith("3 engr") ||
    bio.startsWith("3 engi") ||
    bio.startsWith("third eng")
  );
}

export function isFourthEngr(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("4th eng") ||
    bio.startsWith("4rth eng") ||
    bio.startsWith("4/e") ||
    bio.startsWith("4e") ||
    bio.startsWith("fourth eng")
  );
}

export function isETO(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.startsWith("eto") ||
    bio.startsWith("etr") ||
    bio.startsWith("e.t.o") ||
    bio.startsWith("electro")
  );
}

export function isElectrician(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return bio.startsWith("electric");
}

export function isFitter(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.includes("fitter") || bio.includes("ftr") || bio.includes("welder")
  );
}

export function isWiper(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return bio.includes("wiper") || bio === "wpr";
}

export function isOiler(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return bio.includes("oiler") || bio.includes("oilr");
}

export function isBosun(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.includes("bosun") ||
    bio.includes("bossun") ||
    bio.includes("buson") ||
    bio.includes("busson") ||
    bio.includes("boatswain") ||
    bio.includes("bsn")
  );
}

export function isCook(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return (
    bio.includes("cook") ||
    bio.includes("cck") ||
    bio.includes("chiefcook") ||
    bio.includes("chef") ||
    bio.includes("chief cook") ||
    bio.includes("c/ck")
  );
}

export function isAgencyAdmin(data) {
  const bio = data?.bio?.toLowerCase();
  if (!bio) return false;
  return bio === "agency admin";
}

export function isOfficer(bio) {
  return (
    isMaster(bio) ||
    isChiefMate(bio) ||
    isSecondMate(bio) ||
    isThirdMate(bio) ||
    isFourthMate(bio) ||
    isChiefEngr(bio) ||
    isSecondEngr(bio) ||
    isThirdEngr(bio) ||
    isFourthEngr(bio)
  );
}

export function isPriorityAccount(bio) {
  return (
    isMaster(bio) ||
    isChiefMate(bio) ||
    isSecondMate(bio) ||
    isChiefEngr(bio) ||
    isSecondEngr(bio) ||
    isThirdEngr(bio) ||
    isETO(bio)
  );
}

export function isElectrical(bio) {
  return isETO(bio) || isElectrician(bio);
}

export function getTagByPosition(data) {
  if (isMaster(data)) {
    return MASTER_TAG;
  } else if (isChiefMate(data)) {
    return CHIEF_MATE_TAG;
  } else if (isSecondMate(data)) {
    return SECOND_MATE_TAG;
  } else if (isThirdMate(data)) {
    return THIRD_MATE_TAG;
  } else if (isFourthMate(data)) {
    return FOURTH_MATE_TAG;
  } else if (isChiefEngr(data)) {
    return CHIEF_ENGR_TAG;
  } else if (isSecondEngr(data)) {
    return SECOND_ENGR_TAG;
  } else if (isThirdEngr(data)) {
    return THIRD_ENGR_TAG;
  } else if (isFourthEngr(data)) {
    return FOURTH_ENGR_TAG;
  } else if (isETO(data)) {
    return ETO_TAG;
  } else if (isElectrician(data)) {
    return ELECTRICIAN_TAG;
  } else if (isFitter(data)) {
    return FITTER_TAG;
  } else if (isWiper(data)) {
    return WIPER_TAG;
  } else if (isOiler(data)) {
    return OILER_TAG;
  } else if (isBosun(data)) {
    return BOSUN_TAG;
  } else if (isCook(data)) {
    return CCK_TAG;
  } else if (isAgencyAdmin(data)) {
    return AGENCY_ADMIN_TAG;
  } else {
    return "";
  }
}

export function getDefinedGroupings(list) {
  const DEFAULT = false;
  if (DEFAULT) {
    if (list?.length === 0) {
      return [];
    }
    //group per ranks here, after filtering by rank, make sure the ranks are removed from the list

    //filter first
    const master = list.filter((data) => isMaster(data));
    list = list.filter((data) => !isMaster(data));

    const chiefMate = list.filter((data) => isChiefMate(data));
    list = list.filter((data) => !isChiefMate(data));

    const secondMate = list.filter((data) => isSecondMate(data));
    list = list.filter((data) => !isSecondMate(data));

    const thirdMate = list.filter((data) => isThirdMate(data));
    list = list.filter((data) => !isThirdMate(data));

    const fourthMate = list.filter((data) => isFourthMate(data));
    list = list.filter((data) => !isFourthMate(data));

    const chiefEngr = list.filter((data) => isChiefEngr(data));
    list = list.filter((data) => !isChiefEngr(data));

    const secondEngr = list.filter((data) => isSecondEngr(data));
    list = list.filter((data) => !isSecondEngr(data));

    const thirdEngr = list.filter((data) => isThirdEngr(data));
    list = list.filter((data) => !isThirdEngr(data));

    const fourthEngr = list.filter((data) => isFourthEngr(data));
    list = list.filter((data) => !isFourthEngr(data));

    const eto = list.filter((data) => isETO(data));
    list = list.filter((data) => !isETO(data));

    const electrician = list.filter((data) => isElectrician(data));
    list = list.filter((data) => !isElectrician(data));

    const fitter = list.filter((data) => isFitter(data));
    list = list.filter((data) => !isFitter(data));

    const wiper = list.filter((data) => isWiper(data));
    list = list.filter((data) => !isWiper(data));

    const oiler = list.filter((data) => isOiler(data));
    list = list.filter((data) => !isOiler(data));

    const bosun = list.filter((data) => isBosun(data));
    list = list.filter((data) => !isBosun(data));

    const cook = list.filter((data) => isCook(data));
    list = list.filter((data) => !isCook(data));

    const agencyAdmin = list.filter((data) => isAgencyAdmin(data));
    list = list.filter((data) => !isAgencyAdmin(data));

    //others
    const others = list;

    return {
      master: master || [],
      chiefMate: chiefMate || [],
      secondMate: secondMate || [],
      thirdMate: thirdMate || [],
      fourthMate: fourthMate || [],
      chiefEngr: chiefEngr || [],
      secondEngr: secondEngr || [],
      thirdEngr: thirdEngr || [],
      fourthEngr: fourthEngr || [],
      eto: eto || [],
      electrician: electrician || [],
      fitter: fitter || [],
      wiper: wiper || [],
      oiler: oiler || [],
      bosun: bosun || [],
      cook: cook || [],
      agencyAdmin: agencyAdmin || [],
      others: others || [],
    };
  } else {
    if (!list?.length)
      return {
        master: [],
        chiefMate: [],
        secondMate: [],
        thirdMate: [],
        fourthMate: [],
        chiefEngr: [],
        secondEngr: [],
        thirdEngr: [],
        fourthEngr: [],
        eto: [],
        electrician: [],
        fitter: [],
        wiper: [],
        oiler: [],
        bosun: [],
        cook: [],
        agencyAdmin: [],
        others: [],
      };

    // Initialize all groups
    const groups = {
      master: [],
      chiefMate: [],
      secondMate: [],
      thirdMate: [],
      fourthMate: [],
      chiefEngr: [],
      secondEngr: [],
      thirdEngr: [],
      fourthEngr: [],
      eto: [],
      electrician: [],
      fitter: [],
      wiper: [],
      oiler: [],
      bosun: [],
      cook: [],
      agencyAdmin: [],
      others: [],
    };

    // Single pass through the list
    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      if (isMaster(item)) groups.master.push(item);
      else if (isChiefMate(item)) groups.chiefMate.push(item);
      else if (isSecondMate(item)) groups.secondMate.push(item);
      else if (isThirdMate(item)) groups.thirdMate.push(item);
      else if (isFourthMate(item)) groups.fourthMate.push(item);
      else if (isChiefEngr(item)) groups.chiefEngr.push(item);
      else if (isSecondEngr(item)) groups.secondEngr.push(item);
      else if (isThirdEngr(item)) groups.thirdEngr.push(item);
      else if (isFourthEngr(item)) groups.fourthEngr.push(item);
      else if (isETO(item)) groups.eto.push(item);
      else if (isElectrician(item)) groups.electrician.push(item);
      else if (isFitter(item)) groups.fitter.push(item);
      else if (isWiper(item)) groups.wiper.push(item);
      else if (isOiler(item)) groups.oiler.push(item);
      else if (isBosun(item)) groups.bosun.push(item);
      else if (isCook(item)) groups.cook.push(item);
      else if (isAgencyAdmin(item)) groups.agencyAdmin.push(item);
      else groups.others.push(item);
    }

    return groups;
  }
}
